import React, { Component ,CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InscriptionModal from './inscription-formation-flow';
import { CategorieContext } from '../../context/CategorieContext';
import FormationsData from '../global-components/formations-all';
import LoginInscription from './login-component';
import RegistrerInscription from './register-component';
import InscriptionSession from './inscription-session';
import PaymentChoise from './paiement-section/payment-choise';
import { useCallback } from 'react';
import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit';



class CourseDetails extends Component {
	static contextType = CategorieContext;



	constructor(props) {
		super(props);
		this.state = {
			show: false,
			showHoraire: false,
			selectedSession: null,
			loading: true,
			color: "#ffffff",
			isSuccessfull: false,
			showlogin: false,
			showregister: false,
			showSubscribe: false,
			showSuccessInscription: false,
			showPaymentChoise: false,
			showOrangeMoney: false,

		};
	  }

	  sendToWhatsApp = () => {
		const message = `Bonjour, je suis intéressé par la formation: "${this.props.formation.nom}". Pouvez-vous me fournir plus d'informations ?`;
		const whatsappUrl = `https://wa.me/221767499096/?text=${encodeURIComponent(message)}`;
		window.open(whatsappUrl, '_blank');
	  }

    render() {
		let formation = this.props.formation;
        let publicUrl = process.env.PUBLIC_URL+'/'
		const apiUrl = process.env.REACT_APP_API_BASE;


		const handleCloseLogin = () => this.setState({showlogin: false })
		const handleShowLogin = () => this.setState({showlogin: true })

		const handleCloseRegister = () => this.setState({showregister: false })
		const handleShowRegister = () => this.setState({showregister: true })

		const handleClose = () => this.setState({ show: false  });
	    const handleShow = () => this.setState({ show: true  });

		const handleShowSubscribe = () => this.setState({showSubscribe: true})
		const handleCloseSubscribe = () => this.setState({showSubscribe: false})

		const handleSucess = () => this.setState({ isSuccessfull: true  });


        const handleCloseHoraireModal = () => this.setState({ showHoraireModal: false });
	    const handleShowHoraireModal = (session) => {
            this.setState({ showHoraireModal: true, selectedSession: session });
        };

		const handleCloseSucessInscription = () => this.setState({ showSuccessInscription: false  });
        const handleShowSucessInscription = () => this.setState({ showSuccessInscription: true  });

		const handleShowPaymentChoise = () => this.setState({showPaymentChoise: true})
		const handleClosePaymentChoise = () => this.setState({showPaymentChoise: false})

		const handleCloseOrangeMoney = () => this.setState({ showOrangeMoney: false  });
		const handleShowOrangeMoney = () => this.setState({ showOrangeMoney: true});


		const stylebutton = {
			fontSize: '14px',
            height: '26px',
            lineHeight: '27px',
            color: '#212529',
            backgroundColor: '#ffc600',
            borderRadius: '16px',
        
		}

		const stylesessioninformation = {
			fontSize: '14px',
            
        
		}
		const styleSession = {
			marginBottom: '5px' 
		}

		const styleDate ={
			fontFamily: 'monospace',
			fontWeight: 'normal'
		}

		const ColoredLine = ({ color }) => (
			<hr
				style={{
					color: color,
					backgroundColor: color,
					height: 5
				}}
			/>
		);

		const styleHoraireDetails = {
			height:' 120px',
			width: '100%',
			backgroundColor: '#64b5f6'
		}

		const buttonStyles = {
			width: '100%',
			border: '2px solid rgba(8, 76, 148, 0.2)',
			height: '58px',
			borderRadius: '26px',
			padding: '0 18px',
		  };
		  
		  
    return  <div className="course-single-area pd-top-40 pd-bottom-90">
		
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-8">
			        <div className="course-course-detaila-inner">
			          {/* <div className="details-inner">
			            {/* <div className="emt-user">
			              <span className="u-thumb"><img src={publicUrl+"assets/img/author/1.png"} alt="img" /></span>
			              <span className="align-self-center">Nancy Reyes</span>
			            </div> 
			            {/* <h3 className="title"><a href="course-details.html">{formation.nom} </a></h3> 
			          </div> */}
			          <div className="thumb">
			            <img src={apiUrl+`${formation.image_url_detail}`} alt="img" />
			          </div>
			          <div className="course-details-nav-tab text-center">
			            <ul className="nav nav-tabs" id="myTab" role="tablist">
			              <li className="nav-item">
			                <a className="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">Description</a>
			              </li>
			              <li className="nav-item">
			                <a className="nav-link" id="tab2-tab" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">Contenu</a>
			              </li>
			              <li className="nav-item">
			                <a className="nav-link" id="tab4-tab" data-toggle="tab" href="#tab4" role="tab" aria-controls="tab4" aria-selected="false">Prérequis</a>
			              </li>
			              <li className="nav-item">
			                <a className="nav-link" id="tab3-tab" data-toggle="tab" href="#tab3" role="tab" aria-controls="tab3" aria-selected="false">FAQ</a>
			              </li>
			            </ul>
			          </div>
			          <div className="tab-content" id="myTabContent">
			            <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
			              <div className="course-details-content">
						  <div dangerouslySetInnerHTML={{ __html: formation.description }} />
			                {/* <div className="row pt-4">
			                  <div className="col-sm-12">
			                    <ul className="single-list-wrap">
			                      <li className="single-list-inner style-check-box">
			                        <i className="fa fa-clipboard" /> <span>Objectif general :</span><div dangerouslySetInnerHTML={{ __html: formation.general_objectif}} />

			                      </li>
			                      <li className="single-list-inner style-check-box">

								    <i className="fa fa-clipboard" /><span>Objectif Specifique :</span><div dangerouslySetInnerHTML={{ __html: formation.specifique_objectif}} />

			                      </li>
			                    </ul>
			                  </div>
			                 
			                </div> */}
			              </div>
			            </div>

			            <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
			              <div className="course-details-content">
						     <div dangerouslySetInnerHTML={{ __html: formation.contenue}} />
			              </div>
			            </div>
			            <div className="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="tab3-tab">
			              <div className="course-details-content">
			                <h4 className="title">Overview</h4>
			                <p>The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz, vex nymphs. Waltz, bad nymph, for quick jigs vex! Fox nymphs grab</p>
			                <div id="accordion-1" className="accordion-area mt-4">
			                  <div className="card single-faq-inner style-header-bg">
			                    <div className="card-header" id="ff-five">
			                      <h5 className="mb-0">
			                        <button className="btn-link" data-toggle="collapse" data-target="#f-five" aria-expanded="true" aria-controls="f-five">
			                          01.  What does you simply dummy in do ?
			                          <i className="fa fa-eye" />
			                        </button>
			                      </h5>
			                    </div>
			                    <div id="f-five" className="show collapse" aria-labelledby="ff-five" data-parent="#accordion">
			                      <div className="card-body">
			                        What does you dummy text of free available in market printing has industry been industry's standard dummy text ever.
			                      </div>
			                    </div>
			                  </div>
			                  <div className="card single-faq-inner style-header-bg">
			                    <div className="card-header" id="ff-six">
			                      <h5 className="mb-0">
			                        <button className="btn-link collapsed" data-toggle="collapse" data-target="#f-six" aria-expanded="true" aria-controls="f-six">
			                          02.  What graphics dummy of free  design ?
			                          <i className="fa fa-eye" />
			                        </button>
			                      </h5>
			                    </div>
			                    <div id="f-six" className="collapse" aria-labelledby="ff-six" data-parent="#accordion">
			                      <div className="card-body">
			                        What graphics simply dummy text of free available in market printing industry has been industry's standard dummy text ever.
			                      </div>
			                    </div>
			                  </div>
			                  <div className="card single-faq-inner style-header-bg">
			                    <div className="card-header" id="ff-seven">
			                      <h5 className="mb-0">
			                        <button className="btn-link collapsed" data-toggle="collapse" data-target="#f-seven" aria-expanded="true" aria-controls="f-seven">
			                          03.  Why we are the best ?
			                          <i className="fa fa-eye" />
			                        </button>
			                      </h5>
			                    </div>
			                    <div id="f-seven" className="collapse" aria-labelledby="ff-seven" data-parent="#accordion">
			                      <div className="card-body">
			                        Why we are dummy text of free available in market printing industry has been industry's standard dummy text ever.
			                      </div>
			                    </div>
			                  </div>
			                  <div className="card single-faq-inner style-header-bg">
			                    <div className="card-header" id="ff-eight">
			                      <h5 className="mb-0">
			                        <button className="btn-link collapsed" data-toggle="collapse" data-target="#f-eight" aria-expanded="true" aria-controls="f-eight">
			                          04.  What industries dummy covered ?
			                          <i className="fa fa-eye" />
			                        </button>
			                      </h5>
			                    </div>
			                    <div id="f-eight" className="collapse" aria-labelledby="ff-eight" data-parent="#accordion">
			                      <div className="card-body">
			                        What industries text of free available in market printing industry has been industry's standard dummy text ever.
			                      </div>
			                    </div>
			                  </div>
			                </div>
			              </div>
			            </div>
			            <div className="tab-pane fade" id="tab4" role="tabpanel" aria-labelledby="tab4-tab">
			              <div className="ratings-list-inner mb-4">
						     <div dangerouslySetInnerHTML={{ __html: formation.prerequis}} />
			              </div> 
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4">
			        <div className="td-sidebar">
			          <div className="widget widget_feature">
			            <h4 className="widget-title">Details sur la formation</h4>                                 
			            <ul>
			              <li><i className="fa fa-clock-o" /><span>Duree :</span>{formation.duration}</li>
			              
			              <li><i className="fa fa-map-marker" /><span>Prochaines Sessions:</span></li>
						  {
							formation.sessionFormation.length > 0 && formation.sessionFormation.map((session,index) => (
								<div className="card single-faq-inner style-no-border" key={`session${index}`} style={styleSession}>
			                    <div className="card-header" id={`ff-session${index}`}>
			                      <h5 className="mb-0">
			                        <button className="btn-link collapsed" data-toggle="collapse" data-target={`#f-session${index}`} aria-expanded="true" aria-controls={`f-session${index}`} style={stylesessioninformation}>
									{session.lieu_formation.commune&& `${session.lieu_formation.commune}/`}{session.lieu_formation.region   } <span style={styleDate}>&nbsp;Le {session.date_debut } </span>
			                          <i className="fa fa-eye" />
			                        </button>
			                      </h5>
			                    </div>
			                    <div id={`f-session${index}`} className="collapse  show" aria-labelledby={`ff-session${index}`} data-parent="#accordion">
			                      <div className="card-body">

								  
									<div className="single-list-inner style-check-box">
										<i className="fa fa-money" /> <span>Cout :</span> {session.cout_formation} CFA
									</div>
                                   {formation.mode_paiement && 
									<div className="single-list-inner style-check-box">
											<i className="fa fa-shopping-basket" /> <span >Payable par tranche</span> 
									</div>
								    }
									

									<div className="single-list-inner style-check-box">
                                    <i className="fa fa-calendar" /> <span >Horaire: <Button variant="primary" style={stylebutton} onClick={() => handleShowHoraireModal(session)}>
                                        Afficher
                                    </Button></span>
                                </div>
									
									
			                      
			                      </div>
			                    </div>
			                  </div>
							))
						  }
                          
						  
						 
			            </ul>
						<div className="price-wrap text-center">
							<Button variant="success" size="lg" onClick={this.sendToWhatsApp}>
								<i className="fa fa-whatsapp" aria-hidden="true" /> Contactez-nous sur WhatsApp
							</Button>
						</div>
			            <div className="price-wrap text-center">
						
			              <button className="btn btn-base btn-radius"  onClick={localStorage.getItem('isLoggedIn') === 'true' ? handleShowSubscribe : handleShowLogin}  disabled={formation.sessionFormation.length < 1}>S'inscrire</button>

						 
   
                             {/* Modal for displaying session details */}

							 <LoginInscription handleCloseLogin={handleCloseLogin} 
							                   showlogin={this.state.showlogin} 
											   handleShowRegister={handleShowRegister} 
											   handleCloseRegister={handleCloseRegister}
											   handleShowSubscribe={handleShowSubscribe}
											   handleCloseSubscribe={handleCloseSubscribe}
											   showSubscribe={this.state.showSubscribe}
											    />

							 <RegistrerInscription handleCloseRegister={handleCloseRegister} 
							                       showregister={this.state.showregister} 
												   handleCloseLogin={handleCloseLogin} 
												   handleShowLogin={handleShowLogin}
												   handleShowSubscribe={handleShowSubscribe}
												   handleCloseSubscribe={handleCloseSubscribe}
												   showSubscribe={this.state.showSubscribe}
												   />

							 <InscriptionSession showSubscribe={this.state.showSubscribe} 
							                     handleShowSubscribe={handleShowSubscribe}
												 handleCloseSubscribe={handleCloseSubscribe}
												 formation={formation}
												 handleShowSucessInscription={handleShowSucessInscription}
												 handleShowPaymentChoise={handleShowPaymentChoise}
												 handleClosePaymentChoise={handleClosePaymentChoise}
												 showPaymentChoise={this.state.showPaymentChoise} 
												 handleCloseOrangeMoney={handleCloseOrangeMoney}
												 handleShowOrangeMoney={handleShowOrangeMoney}
												 showOrangeMoney={this.state.showOrangeMoney}
							                     />
							
							
								<Modal show={this.state.showHoraireModal} onHide={handleCloseHoraireModal}>
									<Modal.Header closeButton>
										<Modal.Title>Details sur les Horaires</Modal.Title>
									</Modal.Header>
									<Modal.Body>
										{this.state.selectedSession && this.state.selectedSession.modalites && this.state.selectedSession.modalites.map((modalite, indexmodel) => (
											<div key={`modal ${indexmodel}`}  className="z-depth-5">
												<div className="single-list-inner style-check-box" >
														<i className="fa fa-calendar" /> {modalite.modalite}
												</div>
												<div className="single-list-inner style-check-box" >
														<i className="fa fa-calendar" /> <span>Jours :</span> {modalite.jour}
													</div>
													<div className="single-list-inner style-check-box" >
														<i className="fa fa-clipboard" /> <span>Heures :</span>  {modalite.heure}
													</div>
												{ColoredLine('')}
											</div>
										))}
									</Modal.Body>
									<Modal.Footer>
										<Button variant="secondary" className="btn btn-secondary btn-radius" onClick={handleCloseHoraireModal}>
											Fermer
										</Button>
										<Button className="btn btn-warning btn-radius" 
										       onClick={() =>{
												handleCloseHoraireModal();
												if (localStorage.getItem('isLoggedIn') === 'true') {
											    handleShowSubscribe()
										     }else{handleShowLogin()}   }}
											 >
											S'inscrire
										</Button>
									</Modal.Footer>
								</Modal>

						   
								<Modal show={this.state.show} onHide={handleClose}>
									<Modal.Header closeButton>
									<Modal.Title>Inscription sur la formation {formation.nom} </Modal.Title>
									</Modal.Header>
									<Modal.Body>
									   <InscriptionModal handleClose={handleClose} handleShow={handleShow} formation={formation} handleSucess={handleSucess} />
									</Modal.Body>
								</Modal>
			            </div>
			          </div>
			          {/* <div className="widget widget_catagory">
			            <h4 className="widget-title">Trending Course</h4>                                 
			            <div className="single-course-inner">
			              <div className="thumb">
			                <img src={publicUrl+"assets/img/course/1.png"} alt="img" />
			              </div>
			              <div className="details">
			                <div className="details-inner">
			                  <div className="emt-user">
			                    <span className="u-thumb"><img src={publicUrl+"assets/img/author/1.png"} alt="img" /></span>
			                    <span className="align-self-center">Nancy Reyes</span>
			                  </div>
			                  <h6><a href="course-details.html">Fox nymphs grab quick-jived waltz. Brick quiz whangs</a></h6>
			                </div>
			                <div className="emt-course-meta">
			                  <div className="row">
			                    <div className="col-6">
			                      <div className="rating">
			                        <i className="fa fa-star" /> 4.3
			                        <span>(23)</span>
			                      </div>
			                    </div>
			                    <div className="col-6">
			                      <div className="price text-right">
			                        Price: <span>$54.00</span>
			                      </div>
			                    </div>
			                  </div>
			                </div>
			              </div>
			            </div>
			          </div>  */}
			        </div>
			      </div>
			    </div>
			    <FormationsData />
			  </div>
			</div>

        }
}

export default CourseDetails