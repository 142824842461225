import React, { Component } from 'react';
//import parse from 'html-react-parser';
import { getEvenById, participation } from '../../services/evenement/service';
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import Team from './team-v2';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';


class EventDetails extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			evenData:{},
			center: {},
			showPayment: false,
			payByOrangeMoney: false,
			payByWave: false,
			subscribe: {
				nom: '',
				prenom: '',
				email: '',
				telephone: 'Eleve',
				status: '',
				correctNom: true, 
				correctPrenom: true, 
				correctStatus: true, 
				correctEmail: true, 
				correctTelephone: true 
			}
			
		};
	  }
     
	  async componentDidMount(){
		try {
			const response = await getEvenById(this.props.id);
			this.setState({
				 evenData: response ,
				 center : {
					//14.682203, -17.447907   14.952660932955471, -16.812738099814116  
					lat: parseFloat(response.latittude),
					lng: parseFloat(response.longitude)
				}
				});
		} catch (error) {
			console.error('Error fetching event data:', error);
		}
	  }
	

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
		
		const handleParticipatePayByWave = ()=>{ this.setState({payByWave: true})}
		const handleParticipatePayByOrangeMoney = ()=>{ this.setState({payByOrangeMoney: true})}
		const {position} = this.state
		const { center } = this.state;
		const buttonStyles = {
			width: '100%',
			border: '2px solid rgba(8, 76, 148, 0.2)',
			height: '58px',
			borderRadius: '26px',
			padding: '0 18px',
		};
		const convertData = (dateTo) => {
			const [year, month, day] = dateTo.split('-');
			const months = ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'];
			const formattedDate = `${day} ${months[parseInt(month) - 1]} ${year}`;
			return formattedDate;
		}
        const apiUrl = process.env.REACT_APP_API_BASE;

		const mapStyles = {
			height: '400px',
			width: '100%'
		};
	

		
		const googleMapsApiKey = "AIzaSyBnEQeOmOm6N4mi5SEk6_Kk1AyCUa12WnA";

		const styleImage = {
			with: '130px'
		}
		

		const handleOnChange = (e) => {
			const { name, value } = e.target;
			let correctValue = false;
			if (name === 'nom' || name === 'status') {
				correctValue = value.length >= 2;
			} else if (name === 'email') {
				correctValue = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
			} else if (name === 'telephone') {
				correctValue = /^(\+221|0)?(77|78|76|70|75)[0-9]{7}$/.test(value);
			}
			this.setState({
				subscribe: {
					...this.state.subscribe,
					[name]: value,
					[`correct${name.charAt(0).toUpperCase() + name.slice(1)}`]: correctValue
				}
			});
		}

		const handleShowPayment = () => this.setState({showPayment: true})
		const handleClosePayment = () => this.setState({showPayment: false})


		const buttonStylesOrange = {
			width: '100%',
			border: '2px solid rgba(8, 76, 148, 0.2)',
			height: '58px',
			borderRadius: '26px',
			padding: '0 18px',
			background: '#ff7f00',
		};
		const buttonStylesWave = {
			width: '100%',
			border: '2px solid rgba(8, 76, 148, 0.2)',
			height: '58px',
			borderRadius: '26px',
			padding: '0 18px',
		};

		const handleSubscribe = async (e) =>{
			if (this.state.subscribe.correctEmail && this.state.subscribe.correctTelephone && this.state.subscribe.correctNom && this.state.subscribe.correctStatus && this.state.subscribe.email.length > 0 && this.state.subscribe.telephone.length > 0 && this.state.subscribe.nom.length > 0 && this.state.subscribe.status.length > 0) {
				try {
					const response = await participation(this.props.id,this.state.subscribe)
					Swal.fire({
						position: "top-end",
						icon: "success",
						title: "Votre inscription a été enregistré avec succés !",
						showConfirmButton: false,
						timer: 2000
					  });
					  this.setState({
						subscribe: {
							nom: '',
							prenom: '',
							email: '',
							telephone: '',
							status: '',
							correctNom: true,
							correctPrenom: true,
							correctStatus: true,
							correctEmail: true,
							correctTelephone: true
						}
					});
					document.querySelectorAll('input').forEach(input => input.value = '');

				} catch (error) {

					this.setState({
						subscribe: {
							nom: '',
							prenom: '',
							email: '',
							telephone: '',
							status: '',
							correctNom: true,
							correctPrenom: true,
							correctStatus: true,
							correctEmail: true,
							correctTelephone: true
						}
					});
					document.querySelectorAll('input').forEach(input => input.value = '');

					Swal.fire({
						icon: "warning",
						title: error.response.data,
						showConfirmButton: false,
						timer: 4000
					  });

				}

				
			} else {
				
			}
			
		}


		if (this.state.payByOrangeMoney) {
        
			//return window.location.replace(`https://khoulefreres.com/api/wave/geturlpaiement/35/5/20`);
	
		   return window.location.replace(`https://khoulefreres.com/orangeMoney/participation`);
		}

		if (this.state.payByWave) {
        
			//return window.location.replace(`https://khoulefreres.com/api/wave/geturlpaiement/35/5/20`);
	
		   return window.location.replace(`https://pay.wave.com/m/M_GlPhbRPN_0Q3/c/sn/`);
		}


		const buttonContribution = {
			    /* width: 109%; */
				border: '-11px solid rgba(8, 76, 148, 0.2)',
				height: '43px',
				borderRadius: '26px',
				padding: '0px 12px'
		  };

    return  <div className="course-single-area pd-top-120 pd-bottom-110">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-8 order-lg-12">
		        <div className="event-detaila-inner">
		          <div className="thumb mb-4">
		            <img src={apiUrl+`${this.state.evenData.image_details}`} alt="img" />
		          </div>
		          <ul className="event-meta">
		            <li><i className="fa fa-clock-o" /> {this.state.evenData.date_debut && convertData(this.state.evenData.date_debut)}  a {this.state.evenData.date_fin && convertData(this.state.evenData.date_fin)} </li>
		            <li><i className="fa fa-map-marker" />{this.state.evenData.lieu} </li>
		          </ul>
		          <p> {this.state.evenData.description} </p>
				 
				  <div className="row pt-4 pb-4">
				  {this.state.evenData.articles && this.state.evenData.articles.map((article,index) =>(
					<div className="col-lg-6">
					<div className="thumb mb-1 mb-lg-3">
					<img src={apiUrl+`${article.content}`} alt="img" />
					</div>
				</div>
				  ))}
					
				   </div>
		        </div>
				
				    
				   
				  </div>
		     
		      <div className="col-lg-4 order-lg-1">
		        <div className="td-sidebar">
		          <div className="widget widget_event">
		            <h4 className="widget-title text-white">Informations :</h4>                                 
		            <ul>
		              <li><i className="fa fa-calendar" />Date de debut: {this.state.evenData.date_debut && this.state.evenData.date_debut} </li>
		              <li><i className="fa fa-clock-o" />Heure de debut: {this.state.evenData.heure && this.state.evenData.heure}</li>
		              <li><i className="fa fa-calendar" />Date fin: {this.state.evenData.date_fin && this.state.evenData.date_fin}</li>
		              {/* <li><i className="fa fa-clock-o" />End Time: 11:59 PM</li> */}
		              <li><i className="fa fa-ticket" />Nombre de Participants: {this.state.evenData.nombre_participant && this.state.evenData.nombre_participant}</li>
		              <li><i className="fa fa-map-marker" />Location: {this.state.evenData.lieu && this.state.evenData.lieu}</li>
					  <li><a style={buttonContribution} href={`/event/participation/${this.props.id}`}> <i className="fa fa-money" />Contribuer à l'événement </a></li>
					
		            </ul>
		          </div>

		          <div className="widget widget_catagory">
		            <h4 className="widget-title">Location</h4>                                 
		            <div className="widget-g-map">
					<LoadScript googleMapsApiKey={googleMapsApiKey}>
						<GoogleMap
							mapContainerStyle={mapStyles}
							zoom={15}
							center={center}

						>
						 <MarkerF position={center} />
                        </GoogleMap>
       			   </LoadScript>

		              {/* <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d29208.601361499546!2d90.3598076!3d23.7803374!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1589109092857!5m2!1sen!2sbd" /> */}
		            </div>
		          </div> 
		          <div className="widget widget-contact">
		            <h4 className="widget-title">S'inscrire à l'evenement</h4> 
					<form>
		            <div className= "single-input-inner-custome style-right-icon">
		              <input type="text" placeholder="prenom" name='prenom' onChange={handleOnChange}  required/>
		              <img src={publicUrl+"assets/img/icon/25.png"} alt="img" />
		            </div>
					<div className={this.state.subscribe.correctNom ? "single-input-inner-custome style-right-icon" : "single-input-inner-custome-error style-right-icon"}>
		              <input type="text" placeholder="nom" name='nom'  onChange={handleOnChange}/>
		              <img src={publicUrl+"assets/img/icon/25.png"} alt="img" />
		            </div>
					<div className={this.state.subscribe.correctStatus ? "single-input-inner-custome style-right-icon" : "single-input-inner-custome-error style-right-icon"} >
					<select id="status" name="status" onChange={handleOnChange}>
						<option value="eleve">Élève</option>
						<option value="etudiant">Étudiant</option>
						<option value="professionnel">Professionnel</option>
						<option value="entrepreneur">Entrepreneur</option>
						<option value="diplomeSansEmploi">Diplômé sans emploi</option>
					</select>
		              <img src={publicUrl+"assets/img/icon/29.png"} alt="img" />
		            </div>
		            <div className={this.state.subscribe.correctEmail ? "single-input-inner-custome style-right-icon" : "single-input-inner-custome-error style-right-icon"}>
		              <input type="email" placeholder="Email Address"  name='email' onChange={handleOnChange} required/>
		              <img src={publicUrl+"assets/img/icon/26.png"} alt="img" />
		            </div>
					<div className={this.state.subscribe.correctTelephone ? "single-input-inner-custome style-right-icon" : "single-input-inner-custome-error style-right-icon"}>
		              <input type="text" placeholder="telephone" name='telephone'  onChange={handleOnChange} required/>
		              <img src={publicUrl+"assets/img/icon/28.png"} alt="img" />
		            </div>
		            
		            <Button className="btn btn-base" onClick={handleSubscribe} style={buttonStyles}>S'inscrire</Button>
					</form>
		          </div>
		        </div>
		      </div>
			  
		    </div>
		  </div>
		</div>

        }
}

export default EventDetails